<template>
  <div class="container">
    <div class="header flex-between-start">
      <div class="handle">
        <div class="title">商家行业</div>
        <el-button type="primary" @click="handleEdit()" v-permission="['新增行业分类']">新增行业</el-button>
      </div>
      <div class="search">
        <el-input v-model="name" type="text" placeholder="请输入行业名称" clearable></el-input>
        <el-button @click="pageEmit(1)">搜索</el-button>
      </div>
    </div>
    <!-- 列表 -->
    <el-table :data="tableData.items" v-loading="loading" @row-dblclick="rowDoubleClick">
      <el-table-column prop="id" label="id" width="60"></el-table-column>
      <el-table-column prop="name" label="行业名称"></el-table-column>
      <el-table-column prop="business_count" label="行业商家数量"></el-table-column>
      <el-table-column prop="create_time" label="创建时间" width="180"></el-table-column>
      <el-table-column label="操作" fixed="right" width="275">
        <template #default="scope">
          <el-button plain size="small" type="primary" @click="handleEdit(scope.row)" v-permission="['编辑行业分类']"
            >编辑</el-button
          >
          <el-button
            plain
            size="small"
            type="danger"
            @click="handleDelete(scope.row.id)"
            v-permission="['删除行业分类']"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <page
      @page-event="pageEmit"
      :current-page="pageParam.currentPage"
      :total="tableData.total"
      :page-size="tableData.count"
    ></page>

    <!-- 新增/编辑对话框 -->
    <el-dialog
      :title="edit ? '编辑行业' : '新增行业'"
      :model-value="dialogVisible"
      :before-close="handleClose"
      width="25%"
      :close-on-click-modal="false"
      :destroy-on-close="true"
    >
      <el-form :rules="rules" ref="form" :model="cateForm">
        <el-form-item label="行业名称" prop="name">
          <el-input type="text" v-model="cateForm.name" clearable></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
          <el-button type="primary" @click="handleConfirm">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import merchantModel from '@/model/merchant'
import { ref, onMounted, reactive } from 'vue'
import { ElMessage } from 'element-plus'
import commonHandle from '@/hooks/useCommon'

export default {
  setup() {
    const form = ref(null)
    const name = ref()
    const cateForm = reactive({ name: '', id: '' })
    const rules = {
      name: [{ message: '请输入行业名称', trigger: 'blur', required: true }],
    }
    const dialogVisible = ref(false)
    const edit = ref(false)

    // 获取商家行业列表
    const getData = async pageQuery => {
      try {
        loading.value = true
        const res = await merchantModel.getMerCateList({
          page: pageQuery?.page || pageParam.currentPage - 1,
          count: pageQuery?.count || pageParam.count,
          name: name.value,
        })
        tableData.value = res
      } finally {
        loading.value = false
      }
    }

    // 确认修改、新增
    const handleConfirm = () => {
      form.value.validate(async valid => {
        if (valid) {
          const api = edit.value ? 'editMerCate' : 'addMerCate'
          let res
          try {
            loading.value = true
            res = await merchantModel[api](cateForm)
            ElMessage.success(res.message)
            getData()
          } finally {
            form.value.resetFields()
            loading.value = false
            dialogVisible.value = false
          }
        }
      })
    }

    // 编辑
    const handleEdit = item => {
      if (item) {
        edit.value = true
        cateForm.name = item.name
        cateForm.id = item.id
      } else {
        edit.value = false
        cateForm.name = ''
        cateForm.id = ''
      }
      dialogVisible.value = true
    }

    // 删除
    // const handleDelete = (id) => {
    // 	let res
    //     ElMessageBox.confirm('此操作将永久删除该商家行业, 是否继续?', '提示', {
    //         confirmButtonText: '确定',
    //         cancelButtonText: '取消',
    //         type: 'warning',
    //     }).then(async () => {
    //         try {
    //             loading.value = true
    //             res = await merchantModel.deleteMerCate(id)
    //         } catch (e) {
    //             loading.value = false
    //             console.error(e)
    //         }
    //         if (res.code < window.MAX_SUCCESS_CODE) {
    //             loading.value = false
    //             if (tableData.value.total % pageParam.count === 1 && pageParam.page !== 0) {
    //                 // 判断删除的是不是每一页的最后一条数据
    //                 pageParam.page--
    //             }
    //             await getData()
    //             ElMessage.success(`${res.message}`)
    //         } else {
    //             loading.value = false
    //             ElMessage.error(`${res.message}`)
    //         }
    //     })
    // }
    // 翻页
    // const pageEmit = async (page) => {
    //     const reqPage = page-1;
    //     pageParam.currentPage = page
    //     await getData({page:reqPage, count:tableData.value.count})
    // }

    // 双击行
    const rowDoubleClick = row => {
      handleEdit(row)
    }

    // 关闭弹窗
    const handleClose = done => {
      form.value.resetFields()
      dialogVisible.value = false
      if (typeof done === 'function') done()
    }

    // 删除
    const deleteFun = async id => {
      let res
      res = await merchantModel.deleteMerCate(id)
      return res
    }

    const { tableData, loading, pageParam, handleDelete, pageEmit } = commonHandle(getData, deleteFun)

    onMounted(() => {
      getData()
    })

    return {
      loading,
      pageParam,
      rules,
      tableData,
      dialogVisible,
      edit,
      form,
      name,
      cateForm,
      getData,
      handleEdit,
      handleDelete,
      handleConfirm,
      handleClose,
      rowDoubleClick,
      pageEmit,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/realize/custom';
</style>
